<template>
    <div>
        <b-row>
            <b-col cols="12" class="m-auto text-center">
                <b-form-radio-group
                  v-model="searchType"
                  button-variant="outline-primary"
                  :options="optionsRadio"
                  buttons
                  size="md"
                  name="radios-btn-default"
                />
              </b-col>
            <b-col md="6" v-if="searchType === 'upgradePlan'" class="m-auto">
                <b-card>
                    <g-form @submit="upgradePlan">
                    <b-row>
                        <b-col md="6">
                            <b-form-group>
                              <g-field
                                :value.sync="planUpgrade.tenantId"
                                :dir="isRight ? 'rtl' : 'ltr'"
                                label-text="domin"
                                field="select"
                                name="dealer"
                                rules="required"
                                :options="tenants"
                                label="domainName"
                                @input="() => {}"
                                @change="(v) => {
                                  getSupscription(v.id);
                                  companyName = v.id
                                }"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col md="6">
                            <b-form-group>
                              <g-field
                                :value="companyName"
                                :dir="isRight ? 'rtl' : 'ltr'"
                                label-text="companyName"
                                field="select"
                                name="companyName"
                                :options="tenants"
                                label="name"
                                disabled
                              />
                            </b-form-group>
                          </b-col>
                    </b-row>
                    <b-row class="mb-50 text-center">
                        <b-col md="6"
                          ><p class="h2">{{ $t('startSubscraptionDate') }} :</p></b-col
                        >
                        <b-col md="6"
                          ><span class="h4">{{ getDate(subscription.startDate) }}</span></b-col
                        >
                      </b-row>
                      <b-row class="mb-1 text-center">
                        <b-col md="6"
                          ><p class="h2">{{ $t('endSubscraptionDate') }} :</p></b-col
                        >
                        <b-col md="6"
                          ><span class="h4">{{ getDate(subscription.endDate) }}</span></b-col
                        >
                      </b-row>
                       <hr>
                    <div class="text-center">
                      <b-row>
                        <b-col md="6" class="m-auto">
                          <b-form-group>
                            <g-field
                              :value="subscription.plans.id"
                              :dir="isRight ? 'rtl' : 'ltr'"
                              label-text="mainPlansCat"
                              field="select"
                              name="mainPlansCat"
                              :clearable="false"
                              :options="activePlans"
                              rules="required"
                              label="arabicName"
                              disabled
                            />
                          </b-form-group>
                        </b-col>
                        <b-col md="6" class="m-auto">
                            <b-form-group>
                              <g-field
                                :value.sync="planUpgrade.planId"
                                :dir="isRight ? 'rtl' : 'ltr'"
                                label-text="changedPlansCat"
                                field="select"
                                name="changedPlansCat"
                                :clearable="false"
                                :options="activePlans"
                                rules="required"
                                label="arabicName"
                              />
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12">
                              <hr />
                            </b-col>
                            <b-col cols="12" class="d-flex justify-content-center">
                              <b-button
                                class="mx-1 btn-block"
                                type="submit"
                                variant="primary"
                                :disabled="!planUpgrade.planId"
                                data-action-type="save"
                              >
                                {{ $t("save") }}
                              </b-button>
                            </b-col>
                          </b-row>
                    </div>
                </g-form>
                  </b-card>
            </b-col>
            <b-col md="6" v-if="searchType === 'editPlan'" class="m-auto">
                <b-card>
                    <g-form @submit="updatePlan">
                      <b-row>
                        <b-col md="6">
                            <b-form-group>
                              <g-field
                                :value="selectedItem.tenantId"
                                :dir="isRight ? 'rtl' : 'ltr'"
                                label-text="domin"
                                field="select"
                                name="tenantId"
                                rules="required"
                                :options="tenants"
                                label="domainName"
                                @change="(v) => {
                                  companyName = v.id;
                                  getSupscription(v.id);
                                }"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col md="6">
                            <b-form-group>
                              <g-field
                                :value="companyName"
                                :dir="isRight ? 'rtl' : 'ltr'"
                                label-text="companyName"
                                field="select"
                                name="companyName"
                                :options="tenants"
                                label="name"
                                disabled
                              />
                            </b-form-group>
                          </b-col>
                        </b-row>
                          <b-row class="mb-50 text-center">
                            <b-col md="6"
                              ><p class="h2">{{ $t('startSubscraptionDate') }} :</p></b-col
                            >
                            <b-col md="6"
                              ><span class="h4">{{ getDate(subscription.startDate) }}</span></b-col
                            >
                          </b-row>
                          <b-row class="mb-1 text-center">
                            <b-col md="6"
                              ><p class="h2">{{ $t('endSubscraptionDate') }} :</p></b-col
                            >
                            <b-col md="6"
                              ><span class="h4">{{ getDate(subscription.endDate) }}</span></b-col
                            >
                          </b-row>
                      <b-row class="text-center">
                        <b-col md="6" class="m-auto">
                          <b-form-group>
                            <g-field
                              :value="subscription.plans.id"
                              :dir="isRight ? 'rtl' : 'ltr'"
                              label-text="plansCat"
                              field="select"
                              name="plansCat"
                              :clearable="false"
                              :options="plans"
                              disabled
                              label="arabicName"
                            />
                          </b-form-group>
                        </b-col>
                    </b-row>
                    <hr>
                    <b-row class="text-center">
                        <!-- numberOfUsers -->
                        <b-col md="6" class="m-auto">
                          <g-field
                            label-text="mainNumberOfUsers"
                            field="number"
                            rules="required|numbersNew"
                            readonly
                            name="mainNumberOfUsers"
                            :value="subscription.numberOfUsers"
                            type="number"
                          />
                        </b-col>
                        <!-- numberOfUsers -->
                        <b-col md="6" class="m-auto">
                            <g-field
                              label-text="numberOfUsers"
                              field="number"
                              rules="required|numbersNew"
                              :value.sync="selectedItem.numberOfUsers"
                              type="number"
                            />
                          </b-col>
                    </b-row>
                    <b-row>
                        <!-- numberOfBranches -->
                        <b-col md="6" class="text-center">
                          <g-field
                            label-text="mainNumberOfBranches"
                            field="number"
                            rules="required|numbersNew"
                            readonly
                            name="mainBranchs"
                            :value="subscription.numberOfBranches"
                            type="number"
                          />
                        </b-col>
                        <!-- numberOfBranches -->
                        <b-col md="6" class="text-center">
                            <g-field
                              label-text="numberOfBranches"
                              field="number"
                              rules="required|numbersNew"
                              :value.sync="selectedItem.numberOfBranches"
                              type="number"
                            />
                          </b-col>
                      </b-row>
                      <b-row>
                        <b-col cols="12">
                          <hr />
                        </b-col>
                        <b-col cols="12" class="d-flex justify-content-center">
                          <b-button
                            class="mx-1 btn-block"
                            type="submit"
                            :disabled="!selectedItem.numberOfUsers || !selectedItem.numberOfBranches || !subscription.plans.id"
                            variant="primary"
                            data-action-type="save"
                          >
                            {{ $t("save") }}
                          </b-button>
                        </b-col>
                      </b-row>
                    </g-form>
                  </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { plans } from '@/libs/acl/Lookups';

export default {
  data() {
    return {
      selectedItem: {
        palnId: '',
        numberOfUsers: 0,
        numberOfBranches: 0,
        companyName: null
      },
      planUpgrade: {
        planId: null,
        tenantId: null
      },
      plans,
      mainPlans: [],
      activePlans: [],
      tenants: [],
      searchType: 'upgradePlan',
      subscription: {
        plans: {
            id: null
        }
      }
    };
  },
  computed: {
    optionsRadio() {
      return [
        { text: this.$t('updatePlan'), value: 'upgradePlan' },
        { text: this.$t('editPlan'), value: 'editPlan' },
      ];
  }
  },
  mounted() {
   // this.activePlans = JSON.parse(JSON.stringify(this.plans))
    this.getTeanets()
    this.getPlans()
  },
  methods: {
    getTeanets() {
      this.get({ url: 'Tenants' }).then((data) => {
        this.tenants = data;
      });
    },
    getPlans() {
    this.get({ url: 'Plans' }).then((data) => {
      this.activePlans = data;
      });
    },
    getSupscription(_id) {
    // this.activePlans = JSON.parse(JSON.stringify(this.plans));
      this.get({ url: `Subscriptions?tenantId=${_id}` }).then((data) => {
        // this.subscription = data;
        // if (data.plans.id === 3) {
        //     this.activePlans.forEach(element => {
        //         if (element.id === 2) {
        //             const indx = this.activePlans.indexOf(element);
        //             this.activePlans.splice(indx, 1);
        //         }
        //     });
        //     this.activePlans.forEach(element => {
        //         if (element.id === 1) {
        //             const indx = this.activePlans.indexOf(element);
        //             this.activePlans.splice(indx, 1);
        //         }
        //     });
        // } else if (data.plans.id === 2) {
        //     this.activePlans.forEach(element => {
        //         if (element.id === 1) {
        //             const indx = this.activePlans.indexOf(element);
        //             this.activePlans.splice(indx, 1);
        //         }
        //     });
        // } else if (data.plans.id === 1) {
        //     this.activePlans = this.plans
        // }
        this.subscription = JSON.parse(JSON.stringify(data))
        this.selectedItem = data;
      });
    },
    upgradePlan() {
      this.update({
        url: `Subscriptions/Upgrade?tenantId=${this.planUpgrade.tenantId}&planId=${this.planUpgrade.planId}`
      }).then((data) => {
        this.doneAlert({
          text: this.$t('savedSuccessfully'),
        });
        window.location.reload();
      });
    },
    updatePlan() {
      this.update({
        url: 'Subscriptions',
        data: this.selectedItem,
      }).then((data) => {
        this.doneAlert({
          text: this.$t('savedSuccessfully'),
        });
        window.location.reload();
      });
    },
  },
  };
</script>

<style></style>
